import React, { useState, useEffect } from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Footer.scss";

const Footer = () => {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);

  const togglePrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen);
  };

  useEffect(() => {
    // Close the privacy policy lightbox when clicking outside of it
    const handleOutsideClick = (e) => {
      if (isPrivacyPolicyOpen && e.target.classList.contains("lightbox-overlay")) {
        setIsPrivacyPolicyOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPrivacyPolicyOpen]);

  return (
    <footer className="app__footer">
      <div className="footer-container">
        <div className="app__footer-left">
          <img src={require(`../../assets/bfh.png`)}
                      alt="BFH Logo" />
        </div>
        <div className="footer-info">
          <div className="contact-info">
            <p className="footer-name">Gilles Nikles</p>
            <p className="footer-Email"><a href="mailto:gillesalvar.nikles@students.bfh.ch">gillesalvar.nikles@students.bfh.ch</a></p>
          </div>
          <div className="footer-privacy-policy">
            <p>
              <span className="privacy-link" onClick={togglePrivacyPolicy}>
              Datenschutzerklärung
              </span>
            </p>
            {isPrivacyPolicyOpen && (
              <div className="lightbox-overlay">
                <div className="privacy-policy-lightbox">
                  <h2>Datenschutzerklärung</h2>
                  <p>
                    Die Umfrage ist geschlossen.
                    <br /><br />
                  </p>
                  <button
                    className="close-lightbox"
                    onClick={togglePrivacyPolicy}
                  >
                    Schliessen
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="footer-copyright">
            &copy; {new Date().getFullYear()} Gilles Nikles, Berner Fachhochschule BFH
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "02. contact",
  "app__graybg"
);
