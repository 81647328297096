import React from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Survey.scss";

class MySurveyComponent extends React.Component {
  render() {
    return (
      <div className="survey-closed">
        <h2>Die Umfrage ist geschlossen.</h2>
        <br></br>
        <p>Ich bedanke mich bei allen Personen, die an der Umfrage teilgenommen haben!</p>
      </div>
    );
  }
}

export default AppWrap(
  MotionWrap(MySurveyComponent, "app__about"),
  "02. survey",
  "app__whitebg"
);
